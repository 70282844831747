import React from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';

const Index = () => (
  <Main
    description={"Fadi Bakoura's personal website. Paris based, Software Engineer at Ledger, "}
  >
    <article className="post" id="index">
      <p> Welcome to my website. Please feel free to read more <Link to="/about">about me</Link>,
        or you can check out my {' '}
        <Link to="/blogs">blogs</Link>, {' '}
        <Link to="/resume">resume</Link>, {' '}
        <Link to="/projects">blogs</Link>, {' '}
        view <Link to="/stats">statistics</Link>, {' '}
        or <Link to="/contact">contact</Link> me.
      </p>
    </article>
    <article className="post" id="index">
      <h2 data-testid="heading"><Link to="/">Latest Posts</Link></h2>
      <p> Coming soon...</p>
    </article>
  </Main>
);

export default Index;
